import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from "react-router-dom";
import {App} from "./components/app/app";
import "./utils/localStorage";

import "./styles/main.scss";

ReactDOM.render(
    <Router>
        <App/>
    </Router>,
    document.getElementById('root'))