import React, {useState} from "react";
import {Switch, Route} from "react-router-dom";
import {Search} from "../search/search";
import Cards from "../cards/cards";
import CardPage from "../card-page/card-page";

export const App = () => {
    const search = localStorage.getItem('search') || "";

    const [value, setValue] = useState(search);

    const onSubmit = (e, value) => {
        e.preventDefault();
        localStorage.setItem('search', value)
        setValue(value)
    }

    return (
        <div className="container">
            <Switch>
                <Route path="/" exact>
                    <Search onSubmit={onSubmit}/>
                    <Cards search={value}/>
                </Route>

                <Route path="/:id" render={({match}) => <CardPage id={match.params.id}/>}/>
            </Switch>
        </div>
    )
}