import React from "react";
import {Link} from "react-router-dom";
import star from "./star.svg";
import Moment from "react-moment";

export const Card = ({id, name, stars, updated, url}) => {
    return (
        <div className="card-wrapper">
             <div className="card">
                <div className="card__name">
                    <Link to={`${id}`}>
                        {name}
                    </Link>
                </div>
                 <div className="card__stars">
                     <img src={star} alt="star"/>
                     {stars}
                 </div>
                 <div className="card__commit">
                     Updated -&nbsp;
                     <Moment format="DD.MM.YYYY HH:mm">
                         {updated}
                     </Moment>
                 </div>
                 <div className="card__href">
                     <a href={url} className="card__link">
                         Ссылка на GitHub
                     </a>
                 </div>
             </div>
        </div>
    )
}