import React from "react";

export const Contributors = ({name, img}) => {
    return (
        <div className="contributors">
            <div className="contributors__name">
                {name}
            </div>
            <img src={img} alt={img} className="contributors__img"/>
        </div>
    )
}