import React, {Component, Fragment} from "react";
import {Card} from "../card/card";
import axios from "axios";
import {MOST_POPULAR_REPOS_URL, SEARCH_URL} from "../../constants/API";
import {Pagination} from "../pagination/pagination";

export default class Cards extends Component {

    state = {
        repositories: [],
        totalCount: 0,
        loading: true,
        error: false,
        currentPage: Number(localStorage.getItem('currentPage')) || 1
    }

    componentDidMount() {
        this.fetchRepositories();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.currentPage !== this.state.currentPage || prevProps.search !== this.props.search) {
            this.fetchRepositories();

            if (prevProps.search !== this.props.search) {
                this.setCurrentPage(1)
            }
        }
    }

    fetchRepositories = () => {
        const {currentPage} = this.state;
        const {search} = this.props;
        let url;

        if (search) {
            // Двойной replace
            url = (SEARCH_URL.replace(':SEARCH', search)).replace(':PAGE', currentPage)
        } else {
            url = MOST_POPULAR_REPOS_URL;
        }

        this.setState({
            loading: true
        })

        axios.get(url)
            .then(({data}) => {
                const {items, total_count} = data;

                this.setState({
                    repositories: items,
                    totalCount: search ? (total_count > 1000 ? 1000 : total_count) : 10, // GitHub API выдает только первые 1000 репозиториев. Если ничего не введено, то показываем только первые 10 репозиториев.
                    loading: false,
                    error: false
                })
            })
            .catch(() => {
                this.setState({
                    error: true,
                    loading: false,
                    repositories: []
                })
            })
    }

    onChangePage = (number) => {
        this.setCurrentPage(number)
    }

    setCurrentPage = (currentPage) => {
        localStorage.setItem('currentPage', currentPage);
        this.setState({currentPage})
    }

    render() {
        const {repositories, totalCount, error, loading, currentPage} = this.state;

        if (error) return <div className="indicator">Error!</div>;

        if (loading) return <div className="indicator">Loading...</div>;

        const cards = repositories.map(item => (
            <Card name={item.name}
                  stars={item.stargazers_count}
                  updated={item.pushed_at}
                  url={item.html_url}
                  key={item.id}
                  id={item.id}/>
        ))

        return (
            <Fragment>

                <div className="cards">
                    {
                        cards.length ? cards
                                     : <div className="indicator">No data!</div>
                    }
                </div>

                <Pagination totalItems={totalCount} currentPage={currentPage} onChangePage={this.onChangePage}/>
            </Fragment>
        )
    }
}