import React, {Component, Fragment} from "react";
import axios from "axios";
import {REPO_URL, CONTRIBUTORS_URL} from "../../constants/API";
import {Contributors} from "./contributors";
import {Link} from "react-router-dom";
import Moment from "react-moment";

export default class CardPage extends Component {

    state = {
        loading: true,
        error: false,
        loadingContributors: true,
        errorContributors: false,
        repository: null,
        contributors: []
    }

    componentDidMount() {
        const {id} = this.props;
        const url = REPO_URL.replace(":id", id);

        axios.get(url)
            .then(({data}) => {
                this.setState({
                    repository: data,
                    loading: false,
                    error: false
                })
            })
            .then(() => {
                const {name, owner: {login}} = this.state.repository;
                const url = (CONTRIBUTORS_URL.replace(":login", login)).replace(":repo", name)

                axios.get(url)
                    .then(({data}) => {
                        this.setState({
                            contributors: data,
                            loadingContributors: false,
                            errorContributors: false
                        })
                    })
                    .catch(() => {
                        this.setState({
                            loadingContributors: false,
                            errorContributors: true
                        })
                    })
            })
            .catch(() => {
                this.setState({
                    loading: false,
                    error: true
                })
            })
    }

    render() {
        const {loading, error, loadingContributors, errorContributors, contributors} = this.state;

        if (loading) return <div className="indicator">Loading...</div>;

        if (error) return <div className="indicator">Error!...</div>;

        const {
            name,
            owner: {login, avatar_url, html_url},
            html_url: repo_url,
            description,
            pushed_at,
            language,
            stargazers_count} = this.state.repository;

        return (
            <Fragment>
                <div className="card-page">

                    <div className="back">
                        <Link to="/">
                            Назад
                        </Link>
                    </div>

                    <div className="card-page__author">
                        <img src={avatar_url} alt="avatar" className="card-page__img"/>
                        <a href={html_url} className="card-page__login">
                            <span>{login}</span>
                        </a>
                    </div>
                    <div className="card-page__repo">
                        <a href={repo_url}>
                            {name}
                        </a>
                    </div>
                    <div className="card-page__desc">
                        {description}
                    </div>
                    <div className="card-page__info">
                        <div className="card-page__stars">
                            <strong>Кол-во звезд:</strong> {stargazers_count}
                        </div>
                        <div className="card-page__updated">
                            <strong>Обновлено:</strong>&nbsp;
                            <Moment format="DD.MM.YYYY HH:mm">
                                {pushed_at}
                            </Moment>
                        </div>
                        <div className="card-page__lang">
                            <strong>Язык:</strong> {language ? language : 'Отсутствует'}
                        </div>
                    </div>
                    <div className="card-page__contributors">
                        <div className="card-page__top bolder">Топ 10 контрибьютеров</div>

                        { errorContributors ? <div className="indicator">Error!</div> : null }

                        {
                            (loadingContributors && !errorContributors) ? <div className="indicator">Loading...</div>
                                                : contributors.map((item) => <Contributors img={item.avatar_url} name={item.login} key={item.id}/>)
                        }
                    </div>
                </div>
            </Fragment>
        );
    }
}