import React from "react";
import {itemsPerPage} from "../../constants/pagination";

export const Pagination = ({totalItems, currentPage, onChangePage}) => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
        pageNumbers.push(i);
    }

    const buttons = pageNumbers.map(number => {
        return (
                <li key={number} className="pagination__item">
                    <button className={`pagination__link ${currentPage === number ? 'pagination__active' : ''}`}
                            onClick={() => onChangePage(number)}>
                        {number}
                    </button>
                </li>
            )
        }
    );

    return (
        <div>
            <ul className="pagination">
                {buttons}
            </ul>
        </div>
    );
}