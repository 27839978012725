import React, {useState} from "react";

export const Search = ({onSubmit}) => {

    const search = localStorage.getItem('search') || "";

    const [value, setValue] = useState(search);

    const onChange = (e) => {
        setValue(e.target.value)
    }

    return (
        <form className="form text-center" onSubmit={(e) => onSubmit(e, value)}>
            <input type="text"
                   className="form__input"
                   placeholder="Я ищу..."
                   onChange={onChange}
                   value={value}/>
            <input type="submit" value="Найти" className="form__button"/>
        </form>
    )
}